<kendo-pdf-export paperSize="a4" margin="2cm" #pdf forcePageBreak=".page-break">
@if(content().title) {
<div class="mainHeader">
    <h4>{{content().title}}</h4>
</div>
}

@for (pdfSection of content().pdfSections; track pdfSection) {
    <div>    
        @if(pdfSection.title) {
            <div class="sectionHeader">
                <h5>{{pdfSection.title}}</h5>
            </div>
        }
        @if (pdfSection.pdfSectionType === pdfSectionTypesEnum.Table && pdfExportComponent.castToTableContent(pdfSection.content)) {
            <div class="tableContainer">
                <table class="table">
                    <thead>
                        <tr>
                            @for (header of pdfSection.content.tableHeaders; track header; let index = $index) {
                                <th [style.width] = "header.width" class="tableHeader">
                                    {{header.name}}
                                </th>
                            }
                        </tr>
                    </thead>
                    <tbody>
                        @for (row of pdfSection.content.tableRows; track row; let index = $index) {
                            <tr>
                                @for (column of row.columns; track column; let index = $index) {
                                    <td class="tableValue" [style.font-style]="column.isItalic ? 'italic' : 'normal'">
                                        {{column.content}}
                                    </td>
                                }
                            </tr>
                        }
                    </tbody>
                </table>
            </div>
        } @else if (pdfSection.pdfSectionType === pdfSectionTypesEnum.List && pdfExportComponent.castToStringArray(pdfSection.content)) {
            <div class="listContainer">
                <ul>
                    @for (item of pdfSection.content; track item;) {
                        <li class="listItem"> 
                            {{item}}
                        </li>
                    }
                </ul>
            </div>
        } @else if (pdfSection.pdfSectionType === pdfSectionTypesEnum.Note && pdfExportComponent.castToNoteContent(pdfSection.content)) {
            @for (noteContent of pdfSection.content; track noteContent;) {
                <div class="tableContainer">
                    <div class="info">
                        {{noteContent.info}}
                    </div>
                    <div class="noteContent" [innerHTML]="bypassSanitizeNoteContent(noteContent.noteContent)"></div>
                </div>
            }            
        } 
    </div>
    @if (pdfSection.pdfSectionType === pdfSectionTypesEnum.BreakLine) {
        <div class="page-break"></div>           
    }  

    @if(pdfSection.breakLine) {
        <div class="page-break"></div>
    }
}
</kendo-pdf-export>