import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ViewChild, input } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { NoteSection, PdfContent, PdfSection, TableContent } from '../../entities/pdf-content';
import { PDFExportComponent, PDFExportModule } from "@progress/kendo-angular-pdf-export";
import { createGuid, getCurrency, isNullOrUndefined } from '../util';
import { AdviserService } from '../../services/adviser.service';
import { ContactDetailsService } from '../../services/contact-details.service';
import { CustomField } from '../../enums/custom-field';
import { CustomFieldService } from '../../services/custom-field.service';
import { CustomValueService } from '../../services/custom-value.service';
import { CustomValues } from '../../enums/custom-value';
import { EmploymentDetailsService } from '../../services/employment-details.service';
import { EstatePlanningDetailsService } from '../../services/estate-planning-details.service';
import { FileService } from '../../services/file.service';
import { FileType } from '../../enums/file-type';
import { Folders } from '../../enums/folder';
import { HealthInsuranceService } from '../../services/health-insurance.service';
import { HouseholdService } from '../../services/household.service';
import { JourneyClientService } from '../../services/journey-client.service';
import { JourneyDependantService } from '../../services/journey-dependant.service';
import { JourneyExcludedAdviceAreaService } from '../../services/journey-excluded-advice-area.service';
import { JourneyFileService } from '../../services/journey-file.service';
import { JourneyHoldingService } from '../../services/journey-holding-service';
import { JourneyInsuranceService } from '../../services/journey-insurance-service';
import { JourneyLiabilityService } from '../../services/journey-liability-service';
import { JourneyLogType } from '../../enums/journey-log-type';
import { JourneyLogsService } from '../../services/journey-logs.service';
import { JourneyObjectiveService } from '../../services/journey-objective.service';
import { JourneyPaymentService } from '../../services/journey-payment-service';
import { JourneyPersonalAssetService } from '../../services/journey-personal-asset-service';
import { JourneyService } from '../../services/journey.service';
import { MasterDataService } from '../../services/master-data.service';
import { MatTableModule } from '@angular/material/table';
import { NotesService } from '../../services/notes-service';
import { PdfSectionTypes } from '../../enums/pdf-section-type';
import { PdfService } from '../../services/pdf.service';
import { PrivacyQuestionService } from '../../services/privacy-question.service';
import { SocialSecurityService } from '../../services/social-security.service';
import { WellnessScoreService } from '../../services/wellness-score.service';
import { exportPDF } from '@progress/kendo-drawing';
import { firstValueFrom } from 'rxjs';
import moment from 'moment';

@Component({
  selector: 'pdf-export',
  standalone: true,
  imports: [MatTableModule, PDFExportModule],
  templateUrl: './pdf-export.component.html',
  styleUrl: './pdf-export.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PdfExportComponent {
  constructor(
    private fileService: FileService,
    private journeyFileService: JourneyFileService,
    private journeyService: JourneyService,
    private readonly journeyClientService: JourneyClientService,
    private journeyLogsService: JourneyLogsService,
    private changeDetectorRef: ChangeDetectorRef,
    private privacyQuestionService: PrivacyQuestionService,
    private wellnessScoreService: WellnessScoreService,
    private contactDetailsService: ContactDetailsService,
    private estatePlanningDetailsService: EstatePlanningDetailsService,
    private employmentDetailsService: EmploymentDetailsService,
    private healthInsuranceService: HealthInsuranceService,
    private socialSecurityService: SocialSecurityService,
    private journeyDependantService: JourneyDependantService,
    private journeyPersonalAssetService: JourneyPersonalAssetService,
    private journeyHoldingService: JourneyHoldingService,
    private journeyLiabilityService: JourneyLiabilityService,
    private journeyInsuranceService: JourneyInsuranceService,
    private journeyPaymentService: JourneyPaymentService,
    private journeyObjectiveService: JourneyObjectiveService,
    private journeyExcludedAdviceAreaService: JourneyExcludedAdviceAreaService,
    private customValueService: CustomValueService,
    private notesService: NotesService,
    private houseHoldService: HouseholdService,
    private customFieldService: CustomFieldService,
    private sanitizer: DomSanitizer,
    private adviserService: AdviserService
  ) {
  }

  content = input.required<PdfContent>();
  pdfSectionTypesEnum = PdfSectionTypes;
  pdfExportComponent = PdfExportComponent;
  @ViewChild("pdf", { static: false }) pdfExport!: PDFExportComponent;

  async printAndSave(fileTypeID: number, journeyClientID: string, fileName: string, folderID: number, isJoint: boolean): Promise<PrintSaveResult> {
    const printSaveResult: PrintSaveResult = {
      wasSuccessful: false
    };

    if (!this.houseHoldService.household?.clientGroupID) return printSaveResult;

    try {
      const exportedGroups = await this.pdfExport.export();
      const dataContent = await exportPDF(exportedGroups);
      const split = dataContent.split("base64,");

      const lastJourneyFile = await firstValueFrom(this.journeyFileService.getJourneyFile(fileTypeID, journeyClientID));

      const request = this.fileService.createFileRequest(this.houseHoldService.household.clientGroupID, fileName, split[1],
        this.adviserService.getNonNullableLoggedInUser().node, folderID, lastJourneyFile?.fileID, (isJoint ? null : journeyClientID));
      const response = await firstValueFrom(this.fileService.saveFile(request));
      const code = createGuid()
      const journeyFile = this.journeyFileService.createJourneyFile(journeyClientID, fileName, fileTypeID, code, response.fileID, response.fileVersionID);
      await firstValueFrom(this.journeyFileService.saveJourneyFiles([journeyFile]));
      await this.fileService.uploadFile(code, fileTypeID, journeyClientID, this.journeyService.getNonNullableJourney().journeyID, fileName, dataContent);
      this.pdfExport.saveAs(fileName);
      printSaveResult.wasSuccessful = true;
    } catch (error) {
      const message = `Error occurred while generating the PDF. ${error instanceof Error ? error.message : ""}`;
      printSaveResult.wasSuccessful = false;
      printSaveResult.message = message;

      await firstValueFrom(this.journeyLogsService.saveJourneyLog([
        this.journeyLogsService.createJourneyLog(JourneyLogType.Error, this.journeyService.getNonNullableJourney().journeyID, message)
      ]));
    }

    return printSaveResult;
  }

  async printAndSaveJourneyReport(): Promise<PrintSaveResult> {
    const pdfSections = await Promise.all([
      this.journeyLogsService.getPdfSections(),
      this.privacyQuestionService.getPdfSection(this.journeyService.getNonNullableJourney().journeyID),
      this.wellnessScoreService.getPdfSection(this.journeyService.getNonNullableJourney().journeyID),
      this.getAboutYouPageContent(),
      this.getAdviceFeePdfSection(),
      this.notesService.getPdfSections(this.journeyService.getNonNullableJourney().journeyID)
    ]);
    this.content().pdfSections.push(...pdfSections[0]);
    this.content().pdfSections.push(pdfSections[1]);
    this.content().pdfSections.push(pdfSections[2]);
    this.content().pdfSections.push(PdfService.getPDFBreakLineSection());
    this.content().pdfSections.push(...pdfSections[3]);
    this.content().pdfSections.push(PdfService.getPDFBreakLineSection());
    this.content().pdfSections.push(...this.journeyObjectiveService.getPdfSections());
    this.content().pdfSections.push(this.journeyExcludedAdviceAreaService.getPdfSection());
    this.content().pdfSections.push(PdfService.getPDFBreakLineSection());
    this.content().pdfSections.push(pdfSections[4]);
    this.content().pdfSections.push(PdfService.getPDFBreakLineSection());
    this.content().pdfSections.push(...pdfSections[5]);

    this.changeDetectorRef.detectChanges();
    const fileName = `EA Journey ${this.journeyService.getNonNullableJourney().journeyID} report ${moment().format("DD-MM-yyyy HHmmss")}.pdf`;
    return await this.printAndSave(FileType.FullReport, this.journeyClientService.getNonNullablePrimaryClientID(), fileName, Folders.DigitalEngagementTool, (this.houseHoldService.household?.spouse ? true : false));
  }

  private async getAboutYouPageContent(): Promise<PdfSection[]> {
    const pdfSections: PdfSection[] = [];

    const data = await Promise.all([
      this.contactDetailsService.getPdfSections(this.journeyService.getNonNullableJourney().journeyID),
      this.estatePlanningDetailsService.getPdfSection(this.journeyService.getNonNullableJourney().journeyID),
      this.employmentDetailsService.getPdfSection(this.journeyService.getNonNullableJourney().journeyID),
      this.healthInsuranceService.getPdfSection(this.journeyService.getNonNullableJourney().journeyID),
      this.socialSecurityService.getPdfSection(this.journeyService.getNonNullableJourney().journeyID),
      this.journeyPaymentService.getPdfSections()

    ]);

    pdfSections.push(...[
      this.journeyClientService.getPdfSection(),
      ...data[0],
      ...this.journeyDependantService.getPdfSections(),
      data[1],
      data[2],
      data[3],
      data[4],
      PdfService.getPDFBreakLineSection(),
      ...this.journeyPersonalAssetService.getPdfSections(),
      ...this.journeyLiabilityService.getPdfSections(),
      PdfService.getPDFBreakLineSection(),
      ...this.journeyHoldingService.getPdfSections(),
      PdfService.getPDFBreakLineSection(),
      ...this.journeyInsuranceService.getPdfSections(),
      ...data[5],
    ]);

    return pdfSections;
  }

  static castToTableContent(content: string[] | TableContent | NoteSection[]): content is TableContent {
    return true;
  }

  static castToStringArray(content: string[] | TableContent | NoteSection[]): content is string[] {
    return true;
  }

  static castToNoteContent(content: string[] | TableContent | NoteSection[]): content is NoteSection[] {
    return true;
  }


  async getAdviceFeePdfSection(): Promise<PdfSection> {
    const pdfSection: PdfSection = {
      breakLine: false,
      pdfSectionType: PdfSectionTypes.Table,
      title: "Advice fee overview",
      content: {
        tableHeaders: [
          {
            name: "Field",
            width: "50%"
          },
          {
            name: "Value",
            width: "50%"
          }
        ],
        tableRows: []
      }
    };

    const data = await Promise.all([
      firstValueFrom(this.customValueService.getNonNullableCustomValue(String(CustomValues.StatementOfAdvice))),
      firstValueFrom(this.customValueService.getNonNullableCustomValue(String(CustomValues.OtherAdviceType))),
      firstValueFrom(this.customValueService.getCustomValue(String(CustomValues.AdviceFeePreDiscountFee))),
      firstValueFrom(this.customValueService.getCustomValue(String(CustomValues.AdviceFeeDiscountAmount))),
      firstValueFrom(this.customValueService.getCustomValue(String(CustomValues.AdviceFeeNetAmount))),
      firstValueFrom(this.customValueService.getCustomValue(String(CustomValues.AdviceFeeAgreementOption)))
    ]);

    (pdfSection.content as TableContent).tableRows.push(PdfService.getTableRow({
      name: "Advice document",
      value: MasterDataService.getNameForId(Number(data[0].value), MasterDataService.getStatementOfAdviceTypes())
    }));

    (pdfSection.content as TableContent).tableRows.push(PdfService.getTableRow({
      name: "Other advice type",
      value: MasterDataService.getNameForId(Number(data[1].value), MasterDataService.getOtherAdviceTypes())
    }));

    (pdfSection.content as TableContent).tableRows.push(PdfService.getTableRow({
      name: "Referred by",
      value: this.getAdviceFeeReferredBy()
    }));

    (pdfSection.content as TableContent).tableRows.push(PdfService.getTableRow({
      name: "Advice fee",
      value: isNullOrUndefined(data[2]?.value) ? "N/A" : getCurrency(Number(data[2].value))
    }));

    (pdfSection.content as TableContent).tableRows.push(PdfService.getTableRow({
      name: "Advice fee discount amount",
      value: isNullOrUndefined(data[3]?.value) ? "N/A" : getCurrency(Number(data[3].value))
    }));

    (pdfSection.content as TableContent).tableRows.push(PdfService.getTableRow({
      name: "Advice fee net amount",
      value: isNullOrUndefined(data[4]?.value) ? "N/A" : getCurrency(Number(data[4].value))
    }));

    (pdfSection.content as TableContent).tableRows.push(PdfService.getTableRow({
      name: "Do you accept the Terms of engagement",
      value: isNullOrUndefined(data[5]?.value) ? "N/A" : MasterDataService.getNameForId(Number(data[5].value), MasterDataService.getEaAgreementTypes())
    }));

    return pdfSection;
  }

  bypassSanitizeNoteContent(content: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(content);
  }

  private getAdviceFeeReferredBy(): string {
    const customFieldSelection = this.customFieldService.getHouseholdCustomFieldSingleSelection(CustomField.AdviceFeeDiscountArrangement)
    if (!customFieldSelection?.itemID) {
      return MasterDataService.getNameForId(MasterDataService.getDiscountReferralTypes()[0].iD, MasterDataService.getDiscountReferralTypes());
    }

    return MasterDataService.getNameForId(customFieldSelection.itemID, MasterDataService.getDiscountReferralTypes());
  }
}

export interface PrintSaveResult {
  wasSuccessful: boolean;
  message?: string;
}