import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, firstValueFrom } from 'rxjs';
import { FileRequest } from '../entities/file-request';
import { FileResponse } from '../entities/file-response';
import { Injectable } from '@angular/core';
import { createGuid } from '../shared/util';

@Injectable({
  providedIn: 'root'
})
export class FileService {
  constructor(
    private http: HttpClient
  ) { }

  readonly contentTypeID = 10;

  saveFile(fileRequest: FileRequest): Observable<FileResponse> {
    return this.http.post<FileResponse>("/api/files/", fileRequest);
  }

  async uploadFile(code: string, fileTypeID: number, journeyClientID: string, journeyID: string, name: string, content: string): Promise<void> {
    const presignedURLResponse = await firstValueFrom(this.getPresignedURL(code, fileTypeID, journeyClientID, journeyID, name));

    const base64 = await fetch(content);
    const blob = await base64.blob();
    const headers = new HttpHeaders(presignedURLResponse.headers);
    await firstValueFrom(this.http.put(presignedURLResponse.url, blob, { headers }));
  }

  private getPresignedURL(key: string, fileTypeID: number, journeyClientID: string, journeyID: string, name: string): Observable<PresignedURLResponse> {
    return this.http.get<PresignedURLResponse>(`/api/files/${key}/${fileTypeID}/${journeyID}/${journeyClientID}/${name}`);
  }

  createFileRequest(clientGroupID: string, fileName: string, dataContent: string, userNode: string, folderID: number, fileID?: number, clientID?: string | null): FileRequest {
    return {
      clientID,
      contentType: this.contentTypeID,
      correlationID: createGuid(),
      dataContent,
      fileName,
      folderID,
      userNode,
      fileID,
      clientGroupID
    }
  }
}

export interface PresignedURLResponse {
  key: string;
  url: string;
  headers: | string
  | Record<string, string | number | (string | number)[]>;
}