import { FileService } from './file.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JourneyFile } from '../entities/journey-file';
import { JourneyService } from './journey.service';
import { Observable } from 'rxjs';
import moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class JourneyFileService {
  constructor(
    private http: HttpClient,
    private journeyService: JourneyService,
    private fileService: FileService
  ) { }

  getJourneyFile(fileTypeID: number, journeyClientID: string): Observable<JourneyFile| null> {
    const journeyID = this.journeyService.getNonNullableJourney().journeyID;
    return this.http.get<JourneyFile| null>(`/api/journeyfiles/${journeyID}/${fileTypeID}/${journeyClientID}/latest`);
  }

  saveJourneyFiles(journeyFiles: JourneyFile[]): Observable<JourneyFile[]> {
    return this.http.post<JourneyFile[]>("/api/journeyfiles/", journeyFiles);
  }

  createJourneyFile(journeyClientID: string, fileName: string, fileTypeID: number, code: string, fileID?: number, fileVersionID?: number): JourneyFile {
    return {
      journeyClientID,
      fileName,
      fileID,
      fileVersionID,
      contentTypeID: this.fileService.contentTypeID,
      fileTypeID,
      journeyID: this.journeyService.getNonNullableJourney().journeyID,
      created: moment().utc().toDate(),
      lastModified: moment().utc().toDate(),
      code
    }
  }
}
